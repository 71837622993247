"use client";

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { UseEmblaCarouselType } from "embla-carousel-react";
import Image from "next/image";
import { cn } from "@/app/styling";
import logoSmall from "../../../public/images/landing/logo-small.svg";
import { NextButton, PrevButton, usePrevNextButtons } from "../carousel/arrows";
import BackgroundVideo from "next-video/background-video";
import { VideoProps } from "next-video";
import slide1Video from "../../../videos/video-cut.mp4";

type EmblaCarouselType = Exclude<UseEmblaCarouselType[1], undefined>;

export default function FoundersCarouselSection({
  className,
  onlyMobile = false,
}: {
  className?: string;
  onlyMobile?: boolean;
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel();

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <div
      id="founders"
      className={cn(
        "h-[90vh] max-h-full w-full bg-mavins-grey p-4",
        !onlyMobile && "lg:p-0",
        className,
      )}
    >
      <div
        className="embla__viewport relative flex h-full flex-col overflow-hidden"
        ref={emblaRef}
      >
        <div className="embla__container h-full w-full">
          <div className="embla__slide">
            <VideoSlide src={slide1Video}>
              <SlideContent
                onlyMobile={onlyMobile}
                heading={
                  <Image
                    src={logoSmall}
                    role="presentation"
                    alt="Wildmavins small logo"
                    className="mx-auto w-10"
                  />
                }
                title={
                  <>
                    Founders
                    <br />
                    House
                  </>
                }
                description={
                  <>
                    INCLUDED WITH
                    <br />
                    PURCHASE
                    <br />
                    <svg
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto mt-12 rotate-180 [&_path]:fill-white"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.6095 0.724016C12.1301 1.24472 12.1301 2.08894 11.6095 2.60964L4.55228 9.66683H22.6667C23.4031 9.66683 24 10.2638 24 11.0002C24 11.7366 23.4031 12.3335 22.6667 12.3335H4.55228L11.6095 19.3907C12.1301 19.9114 12.1301 20.7556 11.6095 21.2763C11.0888 21.797 10.2445 21.797 9.72387 21.2763L0.39052 11.943C0.14048 11.693 0 11.3538 0 11.0002C0 10.6466 0.14048 10.3074 0.39052 10.0574L9.72387 0.724016C10.2445 0.203323 11.0888 0.203323 11.6095 0.724016Z"
                        fill="#33363D"
                      />
                    </svg>
                  </>
                }
              />
            </VideoSlide>
          </div>
          <div className="embla__slide">
            <BackgroundSlide
              src="./images/landing/slider-1.png"
              mobileSrc="./images/landing/slider-1-m.png"
              onlyMobile={onlyMobile}
            >
              <SlideContent
                onlyMobile={onlyMobile}
                heading="Founders Membership is a"
                title={
                  <>
                    1:1 unique
                    <br />
                    Digital Artwork
                  </>
                }
                description="by THANKYOUX"
              />
            </BackgroundSlide>
          </div>
          <div className="embla__slide">
            <BackgroundSlide
              src="./images/landing/slider-2.png"
              mobileSrc="./images/landing/slider-2-m.png"
              onlyMobile={onlyMobile}
            >
              <SlideContent
                onlyMobile={onlyMobile}
                title={
                  <>
                    Calatrava I Cedano
                    <br />A GLobal Exclusive
                    <br />
                    Extra AÑejo Tequila.
                  </>
                }
                description={
                  <>
                    1:1000 Founders Edition
                    <br />
                    bottle included with
                    <br />
                    initial membership
                    <br />
                    purchase.
                  </>
                }
              />
            </BackgroundSlide>
          </div>
          <div className="embla__slide">
            <BackgroundSlide
              src="./images/landing/slider-3.png"
              mobileSrc="./images/landing/slider-3-m.png"
              onlyMobile={onlyMobile}
            >
              <SlideContent
                onlyMobile={onlyMobile}
                title={
                  <>
                    Calatrava I cedano
                    <br />
                    Unveiling Event
                  </>
                }
                description="Founders Only"
              />
            </BackgroundSlide>
          </div>
          <div className="embla__slide">
            <BackgroundSlide
              src="./images/landing/slider-4.png"
              mobileSrc="./images/landing/slider-4-m.png"
              onlyMobile={onlyMobile}
            >
              <SlideContent
                onlyMobile={onlyMobile}
                title={
                  <>
                    Wildmavins Member
                    <br />
                    Welcome Gift
                  </>
                }
                className="text-mavins-grey"
              />
            </BackgroundSlide>
          </div>
        </div>

        {/* Pagination */}
        <div
          className="pagination embla__controls absolute bottom-4 left-0 right-0
            grid place-content-center gap-6"
        >
          <div
            className="embla__dots flex flex-wrap items-center justify-end gap-4"
          >
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={cn(
                  `embla__dot m-0 flex h-3 w-3 cursor-pointer touch-manipulation
                  appearance-none items-center justify-center rounded-[50%]
                  border-[1px] border-mavins-grey bg-mavins-beige p-0`,
                  index === selectedIndex &&
                    "embla__dot--selected bg-mavins-gold",
                )}
              />
            ))}
          </div>
        </div>
        {/* TODO: disabled states */}
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </div>
    </div>
  );
}

type BackgroundSlideProps = {
  children?: ReactNode;
  src?: string;
  mobileSrc?: string;
  className?: string;
  onlyMobile?: boolean;
};

const BackgroundSlide = ({
  children,
  src,
  mobileSrc,
  className,
  onlyMobile = false,
}: BackgroundSlideProps) => {
  return (
    <div
      style={{
        ...(src && { backgroundImage: `url('${src}')` }),
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={cn("absolute h-full w-full bg-mavins-beige", className)}
    >
      <div
        style={{
          ...(src && { backgroundImage: `url('${mobileSrc}')` }),
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className={cn(
          "relative inset-0 h-full w-full bg-mavins-beige",
          !onlyMobile && "md:bg-transparent md:!bg-none",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

type VideoSlideProps = {
  children?: ReactNode;
  src: VideoProps["src"];
  className?: string;
};

const VideoSlide = ({ children, src, className }: VideoSlideProps) => {
  return (
    <BackgroundVideo
      src={src}
      className={cn(
        `relative flex h-full w-full flex-col items-center justify-center
        font-sctoGrotesk [&_.next-video-bg-text]:flex
        [&_.next-video-bg-text]:p-0`,
        className,
      )}
      loop
      muted
      controls={false}
      autoPlay
    >
      {children}
    </BackgroundVideo>
  );
};

type SlideContentProps = {
  heading?: ReactNode;
  image?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  className?: string;
  onlyMobile?: boolean;
};

const SlideContent = ({
  heading,
  image,
  title,
  description,
  className,
  onlyMobile,
}: SlideContentProps) => (
  <div
    className={cn(
      `flex h-full w-full flex-col justify-center gap-12 p-8 align-middle
      text-white`,
      className,
    )}
  >
    {heading && (
      <span
        className="mt-[-5rem] text-center font-sctoGrotesk text-2xl font-normal
          leading-6.5 tracking-1"
      >
        {heading}
      </span>
    )}
    {image}
    <h2
      className={cn(
        `text-center font-franklinGothic text-[3rem] font-semibold uppercase
        leading-12 tracking-2`,
        !onlyMobile && "lg:text-9xl lg:leading-24",
      )}
    >
      {title}
    </h2>
    <p
      className={cn(
        "text-center font-sctoGrotesk text-2xl font-normal leading-7 tracking-3",
        !onlyMobile && "lg:text-[2rem] lg:leading-8.5",
      )}
    >
      {description}
    </p>
  </div>
);

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

/**
 * @see https://codesandbox.io/s/s2rxms?file=/src/js/EmblaCarouselDotButton.tsx:146-1407
 */
export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type PropType = React.ComponentPropsWithRef<"button">;

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};
