"use client";

import { cn } from "@/lib/utils";
import ClubCarousel from "../carousel/club-carousel";
import { useState } from "react";

export const ClubSection = ({
  className,
  onlyMobile = false,
}: {
  className?: string;
  onlyMobile?: boolean;
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <div
        id="club-architecture"
        className={cn(
          "relative w-full overflow-hidden bg-mavins-grey",
          className,
        )}
      >
        <div
          className="mx-auto flex max-w-[960px] flex-col gap-8 px-4 pb-8 pt-20"
        >
          <h2
            className="text-center font-franklinGothic text-8xl font-semibold
              uppercase text-mavins-beige"
          >
            Club architecture
          </h2>
          <div
            className="mx-auto flex w-full max-w-[730px] flex-col items-center
              justify-center gap-8 rounded-[0.625rem] border-2
              border-mavins-grey bg-mavins-beige px-12 pb-4 pt-20 text-center"
          >
            <h3
              className="mb-[-0.75rem] font-franklinGothic text-[3.5rem]
                font-semibold uppercase leading-[2.75rem] tracking-3
                text-mavins-grey"
            >
              Founders House
            </h3>
            <span
              className="font-sctoGrotesk text-[0.813rem] font-bold uppercase
                leading-4 tracking-1 text-mavins-grey"
            >
              1000 Members
            </span>
            <label
              onClick={() => setOpened((val) => !val)}
              htmlFor="hide"
              className="order-last flex h-[3.375rem] w-32 items-center
                justify-center gap-2 text-lg font-bold leading-5.5"
            >
              {opened ? "Show More" : "Hide"}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={opened ? "rotate-180" : ""}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.19526 10.804C2.93491 10.5437 2.93491 10.1215 3.19526 9.86119L7.86193 5.19453C8.12227 4.9342 8.5444 4.9342 8.80473 5.19453L13.4714 9.86119C13.7317 10.1215 13.7317 10.5437 13.4714 10.804C13.2111 11.0644 12.7889 11.0644 12.5286 10.804L8.33333 6.60873L4.13807 10.804C3.87772 11.0644 3.45561 11.0644 3.19526 10.804Z"
                  fill="#33363D"
                />
              </svg>
            </label>
            <input
              type="checkbox"
              id="hide"
              className="hidden appearance-none [&:checked+p]:mb-[-2rem]
                [&:checked+p]:max-h-0 [&:checked+p]:translate-y-[-1rem]
                [&:checked+p]:opacity-0"
            />
            <p
              className={cn(
                `mx-auto max-h-full overflow-hidden font-sctoGrotesk text-lg
                font-medium leading-5.5 tracking-1 text-mavins-grey opacity-100
                transition-all`,
                !onlyMobile &&
                  `lg:max-w-[490px] lg:text-[0.813rem] lg:font-normal
                  lg:leading-4`,
              )}
            >
              After the Founders House, additional Sub-Houses will launch,
              granting their members limited access to the offerings within
              their specific house.
              <br />
              <br />
              However,{" "}
              <span className="font-bold">
                Founders will enjoy full access
              </span>{" "}
              to the benefits of every new house, unlocking opportunities to
              connect with creators, explore exclusive products, and engage with
              like-minded communities.
            </p>
          </div>
        </div>
      </div>
      <div
        id="club-houses"
        className={cn(
          "relative mx-auto w-full overflow-hidden bg-mavins-grey px-4 pb-20",
          !onlyMobile && "lg:pb-20",
        )}
      >
        <div className="mx-auto max-w-[730px]">
          <ClubCarousel short onlyMobile={onlyMobile} />
        </div>
      </div>
    </>
  );
};
