"use client";

import React, { ReactNode, useCallback, useEffect, useState } from "react";
import useEmblaCarousel, { UseEmblaCarouselType } from "embla-carousel-react";
import Image from "next/image";
import { cn } from "@/app/styling";
import mezcal from "../../../public/images/landing/mezcal.svg";
import logoGreySmall from "../../../public/images/landing/logo-grey-small.svg";

type EmblaCarouselType = Exclude<UseEmblaCarouselType[1], undefined>;

export default function ClubCarousel({
  short = false,
  onlyMobile = false,
}: {
  short?: boolean;
  onlyMobile?: boolean;
}) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ containScroll: false });

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  return (
    <div>
      <div
        className={`mx-auto hidden max-w-[960px] flex-wrap justify-center gap-8
          ${onlyMobile ? "" : "lg:flex"}`}
      >
        <div className="h-[313px] max-w-[222px]">
          <SpecialCard
            logo={
              <div className="flex flex-col items-center justify-center">
                <Image src={mezcal} alt="" role="presentation" />
                <span className="mt-2.5">TEQUILA MEZCAL</span>
              </div>
            }
            logoBack={
              <div className="flex flex-col items-center justify-center">
                <Image src={mezcal} alt="" role="presentation" />
              </div>
            }
            title={
              <>
                Paradise
                <br />
                House
              </>
            }
            description="Q1 2025"
            color="light"
            back="The Paradise chapter of Wildmavins is dedicated to agave spirits, including mezcals and tequilas, created through collaborations between renowned distillers and designers, offering exclusive products for founder and paradise club members to connect over their shared passion"
          />
        </div>
        <div className="h-[313px] max-w-[222px]">
          <Card
            logo={<Image src={logoGreySmall} alt="" role="presentation" />}
            title={
              <>
                Whisky
                <br />
                Bourbon
                <br />
                House
              </>
            }
            description="Coming Soon"
          />
        </div>
        <div className="h-[313px] max-w-[222px]">
          <Card
            logo={<Image src={logoGreySmall} alt="" role="presentation" />}
            title={
              <>
                Gin
                <br />
                House
              </>
            }
            description="Coming Soon"
          />
        </div>
        <div className="h-[313px] max-w-[222px]">
          <Card
            logo={<Image src={logoGreySmall} alt="" role="presentation" />}
            title={
              <>
                Scotch
                <br />
                House
              </>
            }
            description="Coming Soon"
          />
        </div>
        <div className="h-[313px] max-w-[222px]">
          <Card
            logo={<Image src={logoGreySmall} alt="" role="presentation" />}
            title={
              <>
                Rum
                <br />
                House
              </>
            }
            description="Coming Soon"
          />
        </div>
        <div className="h-[313px] w-[222px] max-w-[222px]">
          <div className="flex h-full w-full items-center justify-center">
            <h4
              className="text-center font-franklinGothic text-[3.5rem]
                font-semibold uppercase leading-[2.8124rem] tracking-3
                text-mavins-lightGrey"
            >
              & More
            </h4>
          </div>
        </div>
      </div>
      <div
        className={`${onlyMobile ? "" : "lg:hidden"} ${
          short ? "" : "h-[681px] lg:h-[600px]" } `}
      >
        <div
          className="embla__viewport relative mx-[-1rem] flex h-full flex-col
            overflow-hidden"
          ref={emblaRef}
        >
          <div className="embla__container h-full w-full">
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <SpecialCard
                logo={
                  <div className="flex flex-col items-center justify-center">
                    <Image src={mezcal} alt="" role="presentation" />
                    <span className="mt-2.5">TEQUILA MEZCAL</span>
                  </div>
                }
                logoBack={
                  <div className="flex flex-col items-center justify-center">
                    <Image src={mezcal} alt="" role="presentation" />
                  </div>
                }
                title={
                  <>
                    Paradise
                    <br />
                    House
                  </>
                }
                description="Q1 2025"
                color="light"
                back="The Paradise chapter of Wildmavins is dedicated to agave spirits, including mezcals and tequilas, created through collaborations between renowned distillers and designers, offering exclusive products for founder and paradise club members to connect over their shared passion"
              />
            </div>
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <Card
                logo={<Image src={logoGreySmall} alt="" role="presentation" />}
                title={
                  <>
                    Whisky
                    <br />
                    Bourbon
                    <br />
                    House
                  </>
                }
                description="Coming Soon"
              />
            </div>
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <Card
                logo={<Image src={logoGreySmall} alt="" role="presentation" />}
                title={
                  <>
                    Gin
                    <br />
                    House
                  </>
                }
                description="Coming Soon"
              />
            </div>
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <Card
                logo={<Image src={logoGreySmall} alt="" role="presentation" />}
                title={
                  <>
                    Scotch
                    <br />
                    House
                  </>
                }
                description="Coming Soon"
              />
            </div>
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <Card
                logo={<Image src={logoGreySmall} alt="" role="presentation" />}
                title={
                  <>
                    Rum
                    <br />
                    House
                  </>
                }
                description="Coming Soon"
              />
            </div>
            <div
              className="embla__slide mx-4 flex !basis-[222px] items-center
                justify-center"
            >
              <h4
                className="text-center font-franklinGothic text-[3.5rem]
                  font-semibold uppercase leading-[2.8124rem] tracking-3
                  text-mavins-lightGrey"
              >
                & More
              </h4>
            </div>
          </div>

          {/* Pagination */}
          <div className="embla__controls mt-20 grid place-content-center gap-6">
            <div
              className="embla__dots flex flex-wrap items-center justify-end
                gap-4"
            >
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={cn(
                    `embla__dot m-0 flex h-3 w-3 cursor-pointer
                    touch-manipulation appearance-none items-center
                    justify-center rounded-[50%] border-0 bg-mavins-lightGrey
                    p-0`,
                    index === selectedIndex &&
                      "embla__dot--selected bg-mavins-beige",
                  )}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Card({
  logo,
  title,
  description,
  color = "dark",
}: {
  logo: ReactNode;
  title: ReactNode;
  description: ReactNode;
  color?: "light" | "dark";
}) {
  return (
    <div
      className={`bg-red relative flex h-[313px] w-[222px] flex-col
        justify-center rounded-[0.625rem] font-sctoGrotesk ${
        color === "light"
            ? "border-2 border-mavins-grey bg-mavins-beige text-mavins-grey"
            : `border-2 border-mavins-lightGrey bg-transparent
              text-mavins-lightGrey`
        }`}
    >
      <div
        className="absolute inset-0 flex h-full flex-col items-stretch p-8 pt-4
          transition-transform duration-700"
      >
        <div
          className="mb-auto flex justify-center font-sctoGrotesk text-xs
            font-medium uppercase leading-3.5 tracking-1"
        >
          {logo}
        </div>
        <h4
          className="text-center font-franklinGothic text-[3.5rem] font-semibold
            uppercase leading-[2.8124rem] tracking-3"
        >
          {title}
        </h4>
        <p
          className="mt-auto text-center font-sctoGrotesk text-[0.813rem]
            font-bold uppercase leading-4 tracking-1"
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export function SpecialCard({
  logo,
  logoBack,
  title,
  description,
  back,
  color = "dark",
}: {
  logo: ReactNode;
  logoBack: ReactNode;
  title: ReactNode;
  back: ReactNode;
  description: ReactNode;
  color?: "light" | "dark";
}) {
  const [flipped, setFlipped] = useState(false);
  return (
    <div
      onClick={() => setFlipped((val) => !val)}
      className={cn(
        `bg-red relative flex h-[313px] w-[222px] flex-col justify-center
        rounded-[0.625rem] font-sctoGrotesk`,
        color === "light"
          ? "border-2 border-mavins-grey bg-mavins-beige text-mavins-grey"
          : `border-2 border-mavins-lightGrey bg-transparent
            text-mavins-lightGrey`,
        flipped &&
          `[&_.back]:[transform:rotateY(0deg)]
          [&_.front]:[transform:rotateY(180deg)]`,
      )}
    >
      <div
        style={{
          transformStyle: "preserve-3d",
          backfaceVisibility: "hidden",
          perspective: "1000px",
        }}
        className="front absolute inset-0 flex h-full flex-col items-stretch p-8
          pt-4 transition-transform duration-700"
      >
        <div
          className="mb-auto flex justify-center font-sctoGrotesk text-xs
            font-medium uppercase leading-3.5 tracking-1"
        >
          {logo}
        </div>
        <h4
          className="text-center font-franklinGothic text-[3.5rem] font-semibold
            uppercase leading-[2.8124rem] tracking-3"
        >
          {title}
        </h4>
        <p
          className="mt-auto text-center font-sctoGrotesk text-[0.813rem]
            font-bold uppercase leading-4 tracking-1"
        >
          {description}
        </p>
      </div>
      <div
        style={{
          transformStyle: "preserve-3d",
          backfaceVisibility: "hidden",
          perspective: "1000px",
        }}
        className="back absolute inset-0 flex h-full flex-col items-stretch p-5
          pt-4 transition-transform duration-700 [transform:rotateY(180deg)]"
      >
        <div
          className="flex justify-center font-sctoGrotesk text-xs font-medium
            uppercase leading-3.5 tracking-1"
        >
          {logoBack}
        </div>
        <p
          className="mb-auto mt-6 text-center font-sctoGrotesk text-sm leading-4
            tracking-1"
        >
          {back}
        </p>
      </div>
    </div>
  );
}

type UseDotButtonType = {
  selectedIndex: number;
  scrollSnaps: number[];
  onDotButtonClick: (index: number) => void;
};

/**
 * @see https://codesandbox.io/s/s2rxms?file=/src/js/EmblaCarouselDotButton.tsx:146-1407
 */
export const useDotButton = (
  emblaApi: EmblaCarouselType | undefined,
): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi],
  );

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList());
  }, []);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  };
};

type PropType = React.ComponentPropsWithRef<"button">;

export const DotButton: React.FC<PropType> = (props) => {
  const { children, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
    </button>
  );
};
